<template>
  <div v-if="!loading">
    <v-btn
      color="primary"
      rounded
      @click="add_news_dialog()"
      fab
      absolute
      top
      right
    >
      <v-icon large>mdi-plus</v-icon>
    </v-btn>
    <div class="mt-5">
      <SelectBriefingLanguageComponent
        @handleChangeLanguage="(e) => (selectedLanguage = e)"
      />
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("title") }}</th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">{{ $t("remove") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(news_obj, index) in company_news.filter(
            (x) => gs.getLanguage(x.Language) === selectedLanguage
          )"
          :key="index"
        >
          <td>{{ news_obj.Title }}</td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_news_dialog(news_obj)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(news_obj)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <v-card>
        <v-card-title>
          <h4 class="dxa_modal_title h4">{{ $t("manage_news") }}</h4>
        </v-card-title>
        <v-card-text v-if="!dialog_loading">
          <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
            <v-select
              :items="index_options"
              :label="$t('index')"
              v-model="news.Index"
              outlined
            ></v-select>
            <v-row>
              <v-col cols="12" md="3">
                <div class="text-center">
                  <v-avatar
                    class="newsImage"
                    tile
                    @click="openFilePicker"
                    color="secondary"
                  >
                    <v-img
                      style="cursor: pointer"
                      v-if="news.Base64Image != null"
                      :src="news.Base64Image"
                    ></v-img>
                    <v-img
                      style="cursor: pointer"
                      v-else-if="news.Image != null"
                      :src="gs.get_photo_path(news.Image)"
                    ></v-img>
                    <v-icon v-else dark x-large>mdi-image</v-icon>
                    <input
                      id="imageNewsInput"
                      name="imageNewsInput"
                      ref="imageNewsInput"
                      type="file"
                      accept="image/png, image/jpeg, image/bmp"
                      style="display: none"
                      @change="setImageNews"
                    />
                  </v-avatar>
                </div>
              </v-col>
              <v-col cols="12" md="9">
                <div class="ml-4">
                  <v-text-field :label="$t('title')" v-model="news.Title" />

                  <v-text-field
                    :label="$t('url')"
                    :rules="[required]"
                    v-model="news.Ref"
                  />
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                class="dxa_modal_btnSuccess"
                color="primary"
                min-width="100"
                :loading="dialog_loading"
                type="submit"
                >{{ $t("save") }}</v-btn
              >
              <v-btn
                class="dxa_modal_btnError"
                color="red"
                min-width="100"
                @click="dialog = false"
                >{{ $t("cancel") }}</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-alert class="ma-2" v-if="error" type="error">{{
            $t(error)
          }}</v-alert>
        </v-card-text>
        <v-container v-else>
          <v-progress-circular
            style="margin-left: 50%"
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Title"
        @close="delete_dialog = false"
        @delete="remove_news"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped>
.newsImage {
  height: 135px !important;
  min-width: 135px !important;
  // width: 48px !important;
  // border-radius: 50% !important;
  // z-index: 20;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import SelectBriefingLanguageComponent from "../components/SelectBriefingLanguageComponent";

export default {
  name: "ManageCompanyNews",

  components: {
    DeleteConfirmationModal,
    SelectBriefingLanguageComponent,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    dialog_loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    company_id: null,
    error: null,
    edit: false,
    company_news: [],
    news: {
      Title: null,
      Ref: null,
      Image: null,
    },
    selectedLanguage: null,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    index_options() {
      var i;
      var return_list = [];
      for (
        i = 1;
        i <=
        this.company_news.filter(
          (x) => this.gs.getLanguage(x.Language) === this.selectedLanguage
        ).length;
        i++
      ) {
        return_list.push(i);
      }
      if (!this.edit) {
        return_list.push(
          this.company_news.filter(
            (x) => this.gs.getLanguage(x.Language) === this.selectedLanguage
          ).length + 1
        );
      }
      return return_list;
    },
  },
  props: {
    BriefingId: Number,
    company_prop: Object,
  },

  async created() {
    await this.get_company_briefing_news();
  },

  methods: {
    async get_company_briefing_news() {
      this.loading = true;
      await this.apiService
        .getRequest(`companynews/list/${this.BriefingId}`)
        .then((resp) => {
          this.company_news = JSON.parse(resp.message);
        });
      this.loading = false;
    },
    openFilePicker() {
      this.$refs.imageNewsInput.click();
    },
    setImageNews(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.news.Base64Image = reader.result;
      };
    },
    open_delete_dialog: function (news) {
      this.delete_obj = news;
      this.delete_dialog = true;
    },
    remove_news: async function (news) {
      this.dialog_loading = true;
      this.error = null;
      await this.apiService
        .deleteRequest(`companynews/delete/${news.Id}`)
        .then((result) => {
          this.company_news = JSON.parse(result.message);
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.$emit("error", error.body.message);
          this.error = error.body.message;
        });
      this.dialog_loading = false;
    },
    add_news_dialog: function () {
      let langId = 0;
      if (this.selectedLanguage === "pt") {
        langId = 0;
      } else if (this.selectedLanguage === "en") {
        langId = 1;
      } else {
        langId = 2;
      }

      this.edit = false;
      this.news = {
        BriefingId: this.BriefingId,
        Title: null,
        Ref: null,
        Base64Image: null,
        Language: langId,
        CompanyId: this.company_prop.CompanyId,
        Index: this.company_news.length + 1,
      };
      this.dialog = true;
    },
    edit_news_dialog: function (news) {
      this.edit = true;
      this.news = {
        Id: news.Id,
        CompanyId: news.CompanyId,
        BriefingId: news.BriefingId,
        Title: news.Title,
        Ref: news.Ref,
        Language: news.Language,
        Image: news.Image,
        Index: news.Index,
        Base64Image: null,
      };
      this.dialog = true;
    },
    checkForm: function () {
      this.error = null;
      var result = this.$refs.form.validate();
      if (result) {
        if (this.news.Base64Image == null && this.news.Image == null) {
          this.error = this.$t("image_required");
        } else {
          this.add_news();
        }
      } else {
        this.dialog_loading = false;
      }
    },
    add_news: async function () {
      if (this.dialog_loading) {
        return;
      }
      this.dialog_loading = true;
      if (this.edit) {
        await this.apiService
          .putRequest("companynews/edit", this.news)
          .then((result) => {
            this.company_news = JSON.parse(result.message);
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      } else {
        await this.apiService
          .postRequest("companynews/add", this.news)
          .then((result) => {
            this.company_news = JSON.parse(result.message);
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      }
      this.dialog_loading = false;
    },
  },
};
</script>
